import {
  HttpErrorResponse,
  HttpHandler,
  HttpHandlerFn,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { throwError, catchError } from 'rxjs';
import { AuthService } from '../../shared/services/auth.service';

export const httpErrorInterceptor: any = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) => {
  const router = inject(Router);
  const authService = inject(AuthService);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error?.status === 401 && router.url != '/login') {
        authService.logout().subscribe();
        router.navigate(['/login']);
      }

      return throwError(() => error);
    })
  );
};
