import { Routes } from '@angular/router';
import { WelcomeComponent } from './welcome/welcome.component';
import { authGuard } from './core/guards/auth.guard';
import { CreateEditBrandComponent } from './modules/main/brands/create-edit-brand/create-edit-brand.component';
import { BrandsTableComponent } from './modules/main/brands/brands-table/brands-table.component';
import { PostsReviewComponent } from './shared/components/posts-review/posts-review.component';

// {
//     path: '',
//     pathMatch: 'full',
//     redirectTo: 'home'
// },
// {
//     path: 'home',
//     component: HomeComponent
// },

// // Option 1: Lazy Loading another Routing Config
// {
//     path: 'flight-booking',
//     loadChildren: () =>
//         import('./booking/flight-booking.routes')
//             .then(m => m.FLIGHT_BOOKING_ROUTES)
// },

// // Option 2: Directly Lazy Loading a Standalone Component
// {
//     path: 'next-flight',
//     loadComponent: () =>
//         import('./next-flight/next-flight.component')
//             .then(m => m.NextFlightComponent)
// },

export const routes: Routes = [
    {
        path: 'login',
        loadComponent: () =>
            import('./core/login/login.component')
                .then(m => m.LoginComponent)
    },
    {
      path: 'forgot-password',
      loadComponent: () =>
        import('./core/forgot-password/forgot-password.component')
          .then(m => m.ForgotPasswordComponent)
    },
    {
      path: 'reset-password',
      loadComponent: () =>
        import('./core/forgot-password/reset-password/reset-password.component')
          .then(m => m.ResetPasswordComponent)
    },
    {
        path: 'brands/:mode', // Child path of main
        component: CreateEditBrandComponent,
    },
    {
        path: 'brands-table', // Child path of main
        component: BrandsTableComponent,
    },

    {
        path: 'welcome',
        canActivate: [authGuard],
        loadComponent: () =>
            import('./welcome/welcome.component')
                .then(m => m.WelcomeComponent)
    },

    // Lazy Loading another Routing Config
    {
        path: 'set-up-wizard',
        canActivate: [authGuard],
        loadChildren: () =>
            import('./modules/set-up-wizard/set-up-wizard.routes')
                .then(m => m.wizardRoutes)
    },
    {
        path: 'main-section',
        canActivate: [authGuard],
        loadChildren: () =>
            import('./modules/main/main.routes')
                .then(m => m.mainRoutes)
    },
    {
        path: '**',
        redirectTo: 'main-section'
    }
];
